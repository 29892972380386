var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "800px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeNmove()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.005"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", { staticClass: "txt_desc none" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("msg.VOSD100.002")) +
                " : " +
                _vm._s(_vm.polNm) +
                " ~ " +
                _vm._s(_vm.$t("msg.VOSD100.003")) +
                " : " +
                _vm._s(_vm.podNm)
            ),
            _c("br"),
            _vm.legMenuChk === "Y"
              ? _c("span", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.parentInfo.rteCd !== undefined,
                          expression: "parentInfo.rteCd !== undefined",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("msg.VOSD100_M5.006")) +
                          " : " +
                          _vm._s(_vm.parentInfo.rteCd) +
                          " / " +
                          _vm._s(_vm.parentInfo.vslNm) +
                          " / " +
                          _vm._s(_vm.parentInfo.voyNo)
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("table", { staticClass: "tbl_search mt10" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", { staticClass: "pl0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.VOSD100_M5.007"))),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      staticClass: "wid150",
                      attrs: {
                        name: "",
                        id: "",
                        disabled:
                          _vm.cntrTypList.length === 1 ||
                          _vm.parentInfo.promotionChk === "Y",
                      },
                      on: { change: _vm.changeCntnTypeV2 },
                    },
                    _vm._l(_vm.cntrTypList, function (vo) {
                      return _c(
                        "option",
                        {
                          key: "sel_" + vo.reqRno,
                          domProps: { value: vo.reqRno },
                        },
                        [_vm._v(_vm._s(vo.asCntrTypCd))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(1),
            _vm._m(2),
            _vm.parentInfo.promotionChk !== "Y"
              ? _c(
                  "tbody",
                  [
                    _vm._l(_vm.surChargeList2Show, function (row, idx) {
                      return _c("tr", { key: idx }, [
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                            staticClass: "text_left",
                          },
                          [
                            _vm.parentInfo.kmtcSpotYn === "Y"
                              ? _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.kmtcPremiumFrtChk,
                                        expression: "kmtcPremiumFrtChk",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "kmtcOnN-" + idx,
                                      value: "R",
                                      disabled:
                                        _vm.parentInfo.detailResp2.bkgClose ===
                                        "Y",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.kmtcPremiumFrtChk,
                                        "R"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.kmtcPremiumFrtChk = "R"
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr10",
                                      attrs: { for: "kmtcOnN-" + idx },
                                    },
                                    [_vm._v(_vm._s(row.frtCdNm))]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.parentInfo.kmtcSpotYn !== "Y"
                              ? _c("span", [_vm._v(_vm._s(row.frtCdNm))])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(row.curCd))]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.cntrTypCd === "RF"
                                  ? "Reefer"
                                  : row.cntrTypCd
                              )
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(row.cgoTypNm))]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [
                            row.rate20 !== "" &&
                            row.rate20 !== "0" &&
                            _vm.frtAppNo === "" &&
                            _vm.of20Rate !== "" &&
                            _vm.of20Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rate20) +
                                            parseInt(_vm.of20Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : row.rate20 !== "" &&
                                row.rate20 !== "0" &&
                                _vm.of20Rate !== "" &&
                                _vm.of20Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rate20) +
                                            parseInt(_vm.of20Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$ekmtcCommon.changeNumberFormat(
                                        row.rate20,
                                        { isComma: true }
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [
                            row.rate40 !== "" &&
                            row.rate40 !== "0" &&
                            _vm.frtAppNo === "" &&
                            _vm.of40Rate !== "" &&
                            _vm.of40Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rate40) +
                                            parseInt(_vm.of40Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : row.rate40 !== "" &&
                                row.rate40 !== "0" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rate40) +
                                            parseInt(_vm.of40Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$ekmtcCommon.changeNumberFormat(
                                        row.rate40,
                                        { isComma: true }
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "O/F" &&
                                  row.cntrTypCd !== "" &&
                                  row.cntrTypCd !== "RH" &&
                                  row.cntrTypCd !== "HC",
                                expression:
                                  "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                              },
                            ],
                          },
                          [
                            row.rateHc !== "" &&
                            row.rateHc !== "0" &&
                            _vm.frtAppNo === "" &&
                            _vm.of40Rate !== "" &&
                            _vm.of40Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rateHc) +
                                            parseInt(_vm.of40Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : row.rateHc !== "" &&
                                row.rateHc !== "0" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          parseInt(row.rateHc) +
                                            parseInt(_vm.of40Rate),
                                          { isComma: true }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$ekmtcCommon.changeNumberFormat(
                                        row.rateHc,
                                        { isComma: true }
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ])
                    }),
                    _vm._l(_vm.bfSurChargeList, function (row, idx) {
                      return _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.parentInfo.kmtcSpotYn === "Y" &&
                                _vm.cntrTypCd2 === "GP",
                              expression:
                                "parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'",
                            },
                          ],
                          key: idx + "Y",
                        },
                        [
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                              staticClass: "text_left",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.kmtcPremiumFrtChk,
                                    expression: "kmtcPremiumFrtChk",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "kmtcOnY-" + idx,
                                  value: "P",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.kmtcPremiumFrtChk, "P"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.kmtcPremiumFrtChk = "P"
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "mr10",
                                  attrs: { for: "kmtcOnY-" + idx },
                                },
                                [_c("span"), _vm._v(_vm._s(row.frtCdNm))]
                              ),
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/images/common/icon_premium_text_only.png"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.curCd))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.cntrTypCd === "RF"
                                    ? "Reefer"
                                    : row.cntrTypCd
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.cgoTypNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              row.rate20 !== "" &&
                              row.rate20 !== "0" &&
                              _vm.frtAppNo === "" &&
                              _vm.of20Rate !== "" &&
                              _vm.of20Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rate20) +
                                              parseInt(_vm.of20Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : row.rate20 !== "" &&
                                  row.rate20 !== "0" &&
                                  _vm.of20Rate !== "" &&
                                  _vm.of20Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rate20) +
                                              parseInt(_vm.of20Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          row.rate20,
                                          { isComma: true }
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              row.rate40 !== "" &&
                              row.rate40 !== "0" &&
                              _vm.frtAppNo === "" &&
                              _vm.of40Rate !== "" &&
                              _vm.of40Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rate40) +
                                              parseInt(_vm.of40Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : row.rate40 !== "" &&
                                  row.rate40 !== "0" &&
                                  _vm.of40Rate !== "" &&
                                  _vm.of40Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rate40) +
                                              parseInt(_vm.of40Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          row.rate40,
                                          { isComma: true }
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd === "O/F" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              row.rateHc !== "" &&
                              row.rateHc !== "0" &&
                              _vm.frtAppNo === "" &&
                              _vm.of40Rate !== "" &&
                              _vm.of40Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rateHc) +
                                              parseInt(_vm.of40Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : row.rateHc !== "" &&
                                  row.rateHc !== "0" &&
                                  _vm.of40Rate !== "" &&
                                  _vm.of40Rate !== undefined
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            parseInt(row.rateHc) +
                                              parseInt(_vm.of40Rate),
                                            { isComma: true }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$ekmtcCommon.changeNumberFormat(
                                          row.rateHc,
                                          { isComma: true }
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.parentInfo.promotionChk === "Y"
              ? _c(
                  "tbody",
                  _vm._l(_vm.surChargeList2Show, function (row, idx) {
                    return _c("tr", { key: idx }, [
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                          staticClass: "text_left",
                        },
                        [_vm._v(_vm._s(row.frtCdNm))]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(row.curCd))]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              row.cntrTypCd === "RF" ? "Reefer" : row.cntrTypCd
                            )
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(row.cgoTypNm))]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [
                          row.cntrTypCd === "GP"
                            ? _c("div", [
                                row.rate20 !== "" &&
                                row.rate20 !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of20Rate !== "" &&
                                _vm.of20Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "20",
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rate20 !== "" &&
                                    row.rate20 !== "0" &&
                                    _vm.of20Rate !== "" &&
                                    _vm.of20Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "20",
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              row.rate20,
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "20",
                                              parseInt(row.rate20),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ])
                            : _c("div", [
                                row.rate20 !== "" &&
                                row.rate20 !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of20Rate !== "" &&
                                _vm.of20Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rate20 !== "" &&
                                    row.rate20 !== "0" &&
                                    _vm.of20Rate !== "" &&
                                    _vm.of20Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate20) +
                                                parseInt(_vm.of20Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            row.rate20,
                                            { isComma: true }
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [
                          row.cntrTypCd === "GP"
                            ? _c("div", [
                                row.rate40 !== "" &&
                                row.rate40 !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rate40 !== "" &&
                                    row.rate40 !== "0" &&
                                    _vm.of40Rate !== "" &&
                                    _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              row.rate40,
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rate40),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ])
                            : _c("div", [
                                row.rate40 !== "" &&
                                row.rate40 !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rate40 !== "" &&
                                    row.rate40 !== "0" &&
                                    _vm.of40Rate !== "" &&
                                    _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rate40) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            row.rate40,
                                            { isComma: true }
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                row.frtCd === "O/F" &&
                                row.cntrTypCd !== "" &&
                                row.cntrTypCd !== "RH" &&
                                row.cntrTypCd !== "HC",
                              expression:
                                "row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                            },
                          ],
                        },
                        [
                          row.cntrTypCd === "GP"
                            ? _c("div", [
                                row.rateHc !== "" &&
                                row.rateHc !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rateHc !== "" &&
                                    row.rateHc !== "0" &&
                                    _vm.of40Rate !== "" &&
                                    _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("del", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              row.rateHc,
                                              { isComma: true }
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v("   "),
                                      _c("img", {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          src: require("../../../assets/images/common/icon_port_arrow.png"),
                                        },
                                      }),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            _vm.calcPromotionDC(
                                              "40",
                                              parseInt(row.rateHc),
                                              row.frtCd,
                                              row.cntrTypCd,
                                              row.cgoTypNm
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ])
                            : _c("div", [
                                row.rateHc !== "" &&
                                row.rateHc !== "0" &&
                                _vm.frtAppNo === "" &&
                                _vm.of40Rate !== "" &&
                                _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : row.rateHc !== "" &&
                                    row.rateHc !== "0" &&
                                    _vm.of40Rate !== "" &&
                                    _vm.of40Rate !== undefined
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$ekmtcCommon.changeNumberFormat(
                                              parseInt(row.rateHc) +
                                                parseInt(_vm.of40Rate),
                                              { isComma: true }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$ekmtcCommon.changeNumberFormat(
                                            row.rateHc,
                                            { isComma: true }
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.parentInfo.promotionChk !== "Y" &&
                    _vm.parentInfo.kmtcSpotYn === "Y" &&
                    _vm.cntrTypCd2 === "GP",
                  expression:
                    "parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'",
                },
              ],
            },
            [
              _vm._v(
                "KMTC ON PREMIUM 운임으로 부킹하시면 특별 선복보장 서비스를 제공합니다."
              ),
            ]
          ),
          _vm.parentInfo.promotionChk !== "Y" &&
          _vm.parentInfo.kmtcSpotYn === "Y" &&
          _vm.cntrTypCd2 === "GP"
            ? _c("br")
            : _vm._e(),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.parentInfo.promotionChk !== "Y" &&
                    _vm.parentInfo.kmtcSpotYn === "Y" &&
                    _vm.cntrTypCd2 === "GP",
                  expression:
                    "parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'",
                },
              ],
              staticStyle: { "font-weight": "bolder" },
            },
            [
              _vm._v(
                "(출항일 포함 7일 이내 PREMIUM 부킹 캔슬시 캔슬비용 20'/40' KRW 50,000/100,000 발생됩니다.)"
              ),
            ]
          ),
          _vm.parentInfo.promotionChk !== "Y"
            ? _c("span", [
                _c(
                  "table",
                  { staticClass: "tbl_col mt10" },
                  [
                    _vm._m(3),
                    _vm._m(4),
                    _vm._l(_vm.surChargeList2Show, function (row, idx) {
                      return _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.kmtcPremiumFrtChk !== "P",
                              expression: "kmtcPremiumFrtChk !== 'P'",
                            },
                          ],
                          key: "a" + idx,
                        },
                        [
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                              staticClass: "text_left",
                            },
                            [_vm._v(_vm._s(row.frtCdNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.curCd))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.cntrTypCd === "RF"
                                    ? "Reefer"
                                    : row.cntrTypCd
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.cgoTypNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate20,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate40,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rateHc,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._l(_vm.bfSurChargeList, function (row, idx) {
                      return _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.kmtcPremiumFrtChk === "P",
                              expression: "kmtcPremiumFrtChk === 'P'",
                            },
                          ],
                          key: "b" + idx,
                        },
                        [
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                              staticClass: "text_left",
                            },
                            [_vm._v(_vm._s(row.frtCdNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.curCd))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.cntrTypCd === "RF"
                                    ? "Reefer"
                                    : row.cntrTypCd
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.cgoTypNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate20,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate40,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtCd !== "SRD" &&
                                    row.frtCd !== "AFS" &&
                                    row.frtPncCd === "P" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rateHc,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "table",
                  { staticClass: "tbl_col mt10" },
                  [
                    _vm._m(5),
                    _vm._m(6),
                    _vm._l(_vm.surChargeList2Show, function (row, idx) {
                      return _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.kmtcPremiumFrtChk !== "P",
                              expression: "kmtcPremiumFrtChk !== 'P'",
                            },
                          ],
                          key: "a" + idx,
                        },
                        [
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                              staticClass: "text_left",
                            },
                            [_vm._v(_vm._s(row.frtCdNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.curCd))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.cntrTypCd === "RF"
                                    ? "Reefer"
                                    : row.cntrTypCd
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.cgoTypNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate20,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate40,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rateHc,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._l(_vm.bfSurChargeList, function (row, idx) {
                      return _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.kmtcPremiumFrtChk === "P",
                              expression: "kmtcPremiumFrtChk === 'P'",
                            },
                          ],
                          key: "b" + idx,
                        },
                        [
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                              staticClass: "text_left",
                            },
                            [_vm._v(_vm._s(row.frtCdNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.curCd))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.cntrTypCd === "RF"
                                    ? "Reefer"
                                    : row.cntrTypCd
                                )
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(row.cgoTypNm))]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate20,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rate40,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    row.frtCd !== "O/F" &&
                                    row.frtCd !== "DCF" &&
                                    row.frtPncCd === "C" &&
                                    row.cntrTypCd !== "" &&
                                    row.cntrTypCd !== "RH" &&
                                    row.cntrTypCd !== "HC",
                                  expression:
                                    "row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.rateHc,
                                      { isComma: true }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.surChargeNoteList.length !== 0,
                        expression: "surChargeNoteList.length !== 0",
                      },
                    ],
                  },
                  [
                    _vm._m(7),
                    _vm._l(_vm.surChargeNoteList, function (row, idx) {
                      return _c("div", { key: idx }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.frtCd === "DCF" ||
                                  row.frtCd === "SRD" ||
                                  row.frtCd === "AFS",
                                expression:
                                  "row.frtCd === 'DCF' || row.frtCd === 'SRD' || row.frtCd === 'AFS'",
                              },
                            ],
                          },
                          [
                            _c("p", { staticClass: "txt_desc2" }, [
                              _vm._v(
                                _vm._s(idx + 1) +
                                  ". Subject to " +
                                  _vm._s(row.frtCdNm) +
                                  " : " +
                                  _vm._s(row.curCd) +
                                  " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeNumberFormat(
                                      row.blRate,
                                      { isComma: true }
                                    )
                                  ) +
                                  " per Bill of Lading."
                              ),
                              _c("br"),
                            ]),
                          ]
                        ),
                        idx === _vm.surChargeNoteList.length - 1
                          ? _c("div", [
                              _c("p", { staticClass: "txt_desc2" }, [
                                _vm._v(
                                  _vm._s(idx + 2) +
                                    ". Subject to SPECIAL DG HANDLING SURCHARGE : USD 600/20', USD 700/40' per container (※For only DG CLASS 2 by BUSAN T/S)."
                                ),
                                _c("br"),
                              ]),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.parentInfo.porCtrCd === "JP",
                                      expression:
                                        "parentInfo.porCtrCd === 'JP'",
                                    },
                                  ],
                                  staticClass: "txt_desc2",
                                },
                                [
                                  _vm._v(
                                    _vm._s(idx + 3) +
                                      ". 3ヶ月更新のLSS(Low Sulphur Surcharge)は、お見積もりのタイミングによって表示されない場合がございます。 料率が決まり次第表示されますが、ご不明な点がございましたら弊社営業部までお問い合わせください。"
                                  ),
                                  _c("br"),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                  ],
                  2
                ),
                _c("table", { staticClass: "tbl_col mt10" }, [
                  _vm._m(8),
                  _c("thead", [
                    _vm._m(9),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.cntrTypCd2 === "GP",
                            expression: "cntrTypCd2 === 'GP'",
                          },
                        ],
                      },
                      [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                          },
                          [_vm._v("GP")]
                        ),
                        _c("th", [_vm._v("HC")]),
                        _c("th", [_vm._v("GP")]),
                        _c("th", [_vm._v("HC")]),
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.cntrTypCd2 === "RF",
                            expression: "cntrTypCd2 === 'RF'",
                          },
                        ],
                      },
                      [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                          },
                          [_vm._v("RF")]
                        ),
                        _c("th", [_vm._v("RH")]),
                        _c("th", [_vm._v("RF")]),
                        _c("th", [_vm._v("RH")]),
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.cntrTypCd2 === "FR",
                            expression: "cntrTypCd2 === 'FR'",
                          },
                        ],
                      },
                      [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                          },
                          [_vm._v("FR")]
                        ),
                        _c("th"),
                        _c("th", [_vm._v("FR")]),
                        _c("th"),
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.cntrTypCd2 === "OT",
                            expression: "cntrTypCd2 === 'OT'",
                          },
                        ],
                      },
                      [
                        _c(
                          "th",
                          {
                            staticStyle: { "border-left": "1px solid #e1e3eb" },
                          },
                          [_vm._v("OT")]
                        ),
                        _c("th"),
                        _c("th", [_vm._v("OT")]),
                        _c("th"),
                      ]
                    ),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.rateFreeDdsList, function (row, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", { staticClass: "text_left" }, [
                          _vm._v(_vm._s(row.demDetCatNm)),
                        ]),
                        _c("td", [_vm._v(_vm._s(row.ldGpFreeDds))]),
                        _c("td", [_vm._v(_vm._s(row.ldHcFreeDds))]),
                        _c("td", [_vm._v(_vm._s(row.disGpFreeDds))]),
                        _c("td", [_vm._v(_vm._s(row.disHcFreeDds))]),
                      ])
                    }),
                    0
                  ),
                ]),
                _c(
                  "table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd === "JP",
                        expression: "memberDetail.userCtrCd === 'JP'",
                      },
                    ],
                    staticClass: "tbl_col mt10",
                  },
                  [
                    _vm._m(10),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("LSS P/C")]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.lssPc,
                                  expression: "lssPc",
                                },
                              ],
                              attrs: { name: "lssPc", id: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.lssPc = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                              ]),
                              _c("option", { attrs: { value: "P" } }, [
                                _vm._v("Prepaid"),
                              ]),
                              _c("option", { attrs: { value: "C" } }, [
                                _vm._v("Collect"),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.parentInfo.promotionChk !== "Y"
            ? _c("span", [
                _c("p", { staticClass: "txt_desc asterisk" }, [
                  _vm._v(
                    _vm._s(_vm.$t("msg.CSBL250.047")) +
                      " : " +
                      _vm._s(_vm.startDate) +
                      " ~ " +
                      _vm._s(_vm.endDate)
                  ),
                  _c("br"),
                ]),
              ])
            : _vm._e(),
          _vm.auth.userCtrCd === "KR" &&
          _vm.checkCharge !== "Y" &&
          (_vm.cntrTypCd2 === "OT" || _vm.cntrTypCd2 === "FR")
            ? _c("span", [
                _c(
                  "span",
                  {
                    staticClass: "txt_desc asterisk",
                    staticStyle: { top: "4px" },
                  },
                  [
                    _c(
                      "a",
                      {
                        ref: "#",
                        staticStyle: {
                          color: "blue",
                          cursor: "pointer",
                          "font-weight": "bold",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clickOpen("oog-notice")
                          },
                        },
                      },
                      [_vm._v("[상세내용]")]
                    ),
                    _vm._v(
                      " 버튼으로 특수화물 선적 및 운임관련 공지를 확인하세요."
                    ),
                    _c("br"),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "txt_desc asterisk",
                    staticStyle: { top: "6px" },
                  },
                  [_vm._v("공지를 확인하셨다면 우측의 체크박스를 체크하세요.")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.oogChk,
                      expression: "oogChk",
                    },
                  ],
                  attrs: { type: "checkbox", id: "agree", name: "agree" },
                  domProps: {
                    checked: Array.isArray(_vm.oogChk)
                      ? _vm._i(_vm.oogChk, null) > -1
                      : _vm.oogChk,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.oogChk,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.oogChk = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.oogChk = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.oogChk = $$c
                      }
                    },
                  },
                }),
                _c(
                  "label",
                  { staticStyle: { top: "5px" }, attrs: { for: "agree" } },
                  [_vm._v(_vm._s(_vm.$t("msg.ADMN030G020.042")))]
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.VOSD100_M5.008")) + "      "),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.checkCharge === "N" &&
                      _vm.parentInfo.detailResp2.bkgClose !== "Y",
                    expression:
                      "checkCharge === 'N' && parentInfo.detailResp2.bkgClose !== 'Y'",
                  },
                ],
                staticClass: "ml5",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "button blue sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.acceptQuote.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.009")))]
                ),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkCharge === "Y",
                    expression: "checkCharge === 'Y'",
                  },
                ],
                staticClass: "ml5",
              },
              [
                _c(
                  "a",
                  { staticClass: "button select sm", attrs: { href: "#" } },
                  [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.009")))]
                ),
                _c("span", { staticClass: "color_blue font_medium" }, [
                  _vm._v("(SC No. : " + _vm._s(_vm.frtAppNo) + ")"),
                ]),
              ]
            ),
          ]),
          _vm.parentInfo.promotionChk === "Y"
            ? _c("span", [
                _c("p", {
                  staticClass: "txt_desc asterisk",
                  domProps: { innerHTML: _vm._s(_vm.$t("msg.CSBL250.052")) },
                }),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.parentInfo.raTsParam !== undefined &&
                    _vm.cntrTypCd2 !== "OT" &&
                    _vm.cntrTypCd2 !== "FR",
                  expression:
                    "parentInfo.raTsParam !== undefined && cntrTypCd2 !== 'OT' && cntrTypCd2 !== 'FR'",
                },
              ],
            },
            [
              _c(
                "a",
                {
                  class: _vm.urlOrNot
                    ? "button blue lg action"
                    : "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goBooking.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Booking")]
              ),
            ]
          ),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.checkCharge === "Y" &&
                    _vm.parentInfo.detailResp2.kmtcSpotUserYn === "Y" &&
                    _vm.parentInfo.kmtcPremiumNegoYn === "Y" &&
                    _vm.parentInfo.detailResp2.bkgClose !== "Y" &&
                    _vm.cntrTypCd2 === "GP",
                  expression:
                    "checkCharge === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y' && cntrTypCd2 === 'GP'",
                },
              ],
              staticClass: "button green lg mr5",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goReqQtPop("K")
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("msg.MAIN010G030.038")) + " NOW로 운임 협의하기"
              ),
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cntrTypCd2 === "OT" || _vm.cntrTypCd2 === "FR",
                  expression: "cntrTypCd2 === 'OT' || cntrTypCd2 === 'FR'",
                },
              ],
            },
            [
              _c(
                "a",
                {
                  class: _vm.urlOrNot
                    ? "button blue lg action"
                    : "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickOpen("oog-cargo-chk-pop")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.510")))]
              ),
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.checkCharge === "Y" &&
                    _vm.parentInfo.promotionChk !== "Y" &&
                    _vm.parentInfo.detailResp2.kmtcSpotUserYn !== "Y" &&
                    _vm.parentInfo.kmtcSpotUserYn !== "Y",
                  expression:
                    "checkCharge === 'Y' && parentInfo.promotionChk !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && parentInfo.kmtcSpotUserYn !== 'Y'",
                },
              ],
            },
            [
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goNegoPop.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.010")))]
              ),
            ]
          ),
          _vm.parentInfo.promotionChk !== "Y" &&
          _vm.parentInfo.detailResp2.kmtcSpotUserYn !== "Y"
            ? _c("span", [
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnFrtConfirm()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.026")))]
                ),
              ])
            : _vm._e(),
          _vm._v("  "),
          _c("span", [
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeNmove()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.VOSD100.042")))]
            ),
          ]),
          _vm._v("  "),
          _c("span", [
            _c(
              "a",
              {
                staticClass: "button lg",
                attrs: { href: "javascript:none(0)" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.screenPrint.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "btn_icon print" }), _vm._v("인쇄")]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.checkCharge === "Y" &&
                  _vm.parentInfo.detailResp2.kmtcSpotUserYn === "Y" &&
                  _vm.parentInfo.kmtcPremiumNegoYn === "Y" &&
                  _vm.parentInfo.detailResp2.bkgClose !== "Y",
                expression:
                  "checkCharge === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'",
              },
            ],
            staticClass: "flex_box mt10",
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  color: "#075bb9",
                  "font-weight": "500",
                  "font-size": "15px",
                  "margin-left": "31%",
                  "margin-top": "-10px",
                },
              },
              [
                _vm._v(
                  "KMTC ON NOW를 클릭해보세요. e-KMTC에서 운임을 즉시 협의하실 수 있습니다."
                ),
              ]
            ),
          ]
        ),
        _c("div", { attrs: { id: "pdf_area" } }),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "80px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Freight Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Export Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Import Charges")]),
        _c("th", [_vm._v("Currency")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("Cargo")]),
        _c("th", [_vm._v("20'")]),
        _c("th", [_vm._v("40'")]),
        _c("th", [_vm._v("HC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt_desc asterisk" }, [
      _vm._v("Notes"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { rowspan: "2" } }, [_vm._v("KIND")]),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Loading")]),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Discharging")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "20%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }